/* * @Author: liuzhixiang * @Date: 2021-02-06 14:49:09 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-07 14:14:30 */
<template>
  <div class="errors404">
    <div class="container">
      <div class="content">
        <div class="title">功能正在研发中，敬请期待</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.errors404 {
  // font-size: 50px;font-weight: bold;text-shadow: 2px 2px 5px #615e4b;margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #b0bec5;
  display: table;
  font-weight: 100;
  .container {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    padding-top:calc((100vh - 96px)/2);
    .content {
      text-align: center;
      display: inline-block;
      .title {
        font-size: 42px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
